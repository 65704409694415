import React from "react";

import Market from "./Market";

const Home = () => {
   return (
      <div>
         <Market />
      </div>
   );
};

export default Home;
